
// 隐私政策
import {computed, defineComponent, reactive, toRefs} from "vue";
import Protocol from '@/components/ProtocolSet.vue'
import {serviceConfig} from "@/utils/config";

interface IState {
  detail: string
  loading: boolean,
}

export default defineComponent({
  name: "ProtocolDetail",
  components: {Protocol},
  setup() {
    const state = reactive({
      activeKey: serviceConfig[0]?.id || 'key',
      loading: false,
      // courier_service(用户协议)，courier_welfare(司机福利)，courier_privacy(隐私政策)，courier_settled(入驻协议)
      tabs: serviceConfig
    })

    const title = computed(() => {
      return state.tabs.find(e => e.id === state.activeKey)?.name || '协议配置'
    })

    const setActive = (key: string) => {
      state.loading = true
      setTimeout(() => {
        state.activeKey = key
        state.loading = false
      }, 10)
    }

    return {
      ...toRefs(state),
      title,
      setActive,
    }
  }
})
